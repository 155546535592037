import React from 'react';

function MFooter() {
    return (
        <div className='responsive-map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.4599122902096!2d92.79490677427992!3d26.633753272025235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3744e921596bbd8d%3A0xfce8fe53e9ef451a!2sORKID%20SOFTWARE%20SERVICES!5e0!3m2!1sen!2sin!4v1712282541795!5m2!1sen!2sin" width="400" height="450" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default MFooter;