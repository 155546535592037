import React, { useState } from "react";
import {
  AppBar,
  Button,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
import "./Review.css";
//import AlumniRegForm from "./AlumniRegForm";
import ProductList from "./ProductList";




const Alumni = () => {

  const [value, setValue] = useState();
  const theme = useTheme(); 
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));


  return (   
      
    <React.Fragment>
    <AppBar sx={{ background: "#063970" }}>
      <Toolbar>
       <img src="images/orkidicon.png" alt=""  sx={{ transform: "scale(2)"}}  /> 
              Orkid Software Services
        {isMatch ? (
          <>
            <Typography sx={{ fontSize: "1.1rem", paddingLeft: "15%" }}>             
            </Typography>             
            <DrawerComp />
          </>
        ) : (
          <>
            <Tabs
              sx={{ marginLeft: "auto" }}
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={(e, value) => setValue(value)}
            >            
            </Tabs>
            
            <Button sx={{ marginLeft: "auto" }} variant="contained" >
                Alumni Registration
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained">
                Notice Board
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained">
                Contactus
              </Button>

           
          </>
        )}
      </Toolbar>
      </AppBar>

     
    
      <div className="App">
      <h1>Alumni Registration </h1>
      </div>    
      { /* <AlumniRegForm/>   */}
      <ProductList/>
 
 

    
  </React.Fragment>

  
    
  );
 
  
};



export default Alumni;