export const dataDigitalBestSeller = [
    {
      id: 1,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:'/images/home5.avif', 
    },
    {
      id: 2,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home6.avif',
    },
    {
      id: 3,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home7.avif',
    },
    {
      id: 4,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home8.avif',
    },
    {
      id: 5,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home9.avif',
    },
    {
      id: 6,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home10.avif',
    },
    {
      id: 7,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home4.jpg',
    },
    {
      id: 8,
      title: 'About US',
      price: 'description',
      category: 'Designation',
      linkImg:
        '/images/home5.avif',
    },
  ];