import facebook from './media/facebook.png'
import twitter from './media/twitter.png'
import instagram from './media/instagram.png'
import linkedin from './media/linkedin.png'
import youtube from './media/youtube.png'


export const MediaData = [
    {
        img:youtube,
        title: "youtube",
        //href:"https://www.youtube.com/@gooddomicroprojects/"
        href:"https://www.youtube.com"
      },
      {
        img:facebook,
        title: "facebook",
        href: "https://www.facebook.com"
      },
      {
        img:instagram,
        title: "instagram",
        href: "https://www.instagram.com"
      },
      {
        img:twitter,
        title: "twitter",
        href: "https://twitter.com"
      },
      {
        img:linkedin,
        title: "linkedin",
        href: "https://linkedin.com"
      },
    
    
]