import React from 'react';
import { MediaData } from './MediaData';
import "./Footer.css";

const MainPage = () => {
  return (
    <React.Fragment>
    <main>
      <div className="centered">
  
            {
                MediaData.map((item, index) => {
                    return(
                        <div key={index}>
                            <a href={item.href} target="_blank">
                            <img src={item.img} alt='media' />
                            </a>
                        </div>
                    )
                })
            }
        </div>
   
    </main>
  </React.Fragment>
  )
}

export default MainPage;