import firebase from'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { getFirestore } from "firebase/firestore";





const firebaseConfig = {

  apiKey: "AIzaSyCTo2E5qhZkBS3RKhQSs9exqrtkXRSMF-0",
  authDomain: "tbbhssalumniorckid-ff5ca.firebaseapp.com",
  projectId: "tbbhssalumniorckid-ff5ca",
  storageBucket: "tbbhssalumniorckid-ff5ca.appspot.com",
  messagingSenderId: "131008072108",
  appId: "1:131008072108:web:504ed420dd1036d8611209"

};






  

firebase.initializeApp(firebaseConfig);

const auth=firebase.auth();
const fs=firebase.firestore();
const storage=firebase.storage();
const app=firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);


export  {auth,fs,storage,db}