import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import {useState, useEffect} from "react";
import { db } from "./firebase";
import {
  collection,
  updateDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { useAppStore } from "./appStore";


export default function EditProduct({ fid,closeEvent }) {


  const [firstName, setFirstName] = useState("")
  const [lastName, setLastname] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

const setRows=useAppStore((state)=> state.setRows);
const userCollectionRef = collection(db, "Alumnireg");

useEffect(() =>{
  console.log("FID :"+fid.id);
  setFirstName(fid.firstName);
  setLastname(fid.lastName);
  setAddress(fid.address);
  setPhone(fid.phone);
  setEmail(fid.email);
  setMessage(fid.message);
},[]);

const handlefirstNameChange = (event) => {
  setFirstName(event.target.value);
};
const handleLastNameChange = (event) => {
  setLastname(event.target.value);
};
const handleAddressChange = (event) => {
  setAddress(event.target.value);
};
const handlePhoneChange = (event) => {
  setPhone(event.target.value);
};
const handleEmailChange = (event) => {
  setEmail(event.target.value);
};
const handleMessageChange = (event) => {
  setMessage(event.target.value);
};
const createUser = async () => {

  const userDoc=doc(db,"Alumnireg",fid.id);
    const newFields={
      firstName:firstName,
      lastName:lastName,
      address:address,
      phone:phone,
      email:email,
     message:message
    };

    await updateDoc(userDoc,newFields);
    getUsers();
    closeEvent();
    Swal.fire("Edited", "Your file has been submitted.", "Success");
};

const getUsers = async () => {
  const data = await getDocs(userCollectionRef);
  setRows(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
};


  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
       Edit Product
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Box height={20} />
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            size="small"
            onChange={handlefirstNameChange}
            value={firstName}
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Session"
            variant="outlined"
            size="small"
            
            
            onChange={handleLastNameChange}
            value={lastName}
            sx={{ minWidth: "100%" }}

            InputProps={{
              startAdornment: <InputAdornment position="start">
              
              </InputAdornment>,
            }}
           
         
            
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Address"
            variant="outlined"
            size="small"
        
            
            onChange={handleAddressChange}
            value={address}
            sx={{ minWidth: "100%" }}

            
           
         
            
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Phone"
            variant="outlined"
            size="small"
        
            
            onChange={handlePhoneChange}
            value={phone}
            sx={{ minWidth: "100%" }}

           
         
            
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            size="small" 
            onChange={handleEmailChange}
            value={email}
            sx={{ minWidth: "100%" }}          
            
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Message"        
            variant="outlined"
            size="small"
            onChange={handleMessageChange}
            value={message}
            sx={{ minWidth: "100%" }}
          />
                     
        
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Button variant="contained" onClick={createUser}>
              Submit
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ m: 4 }} />
    </>
  );
}
